<template>
  <div>
    <div
      class="modal fade"
      id="modalAddBienBan"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddBienBanLabel"
      aria-hidden="true"
    >
      <div v-loading="loading" class="modal-dialog modal-xl" role="document" v-if="currDisease">
        <div class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5
              class="modal-title"
              id="modalAddBienBanLabel"
            >{{ $t("multidisciplinary_board.lbl_mdt_report") }}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-6">
                <p class="fs-14 txt-grey-900 mb-2">
                  {{ $t("multidisciplinary_board.lbl_patients_name") }}:
                  <span class="fw-700">
                    {{
                    currDisease && currDisease.patient_name
                    }}
                  </span>
                </p>
                <p class="fs-14 txt-grey-900 mb-2">
                  {{ $t("multidisciplinary_board.lbl_address") }}:
                  <span>{{ form.address }}</span>
                </p>
                <div class="d-flex">
                  <p
                    class="fs-14 txt-grey-900 mb-2 mr-1"
                  >{{ $t("multidisciplinary_board.lbl_diagnose") }}:</p>
                  <div class="text-black" v-html="patientDiagnostic" />
                </div>
                <p class="fs-14 txt-grey-900 mb-2">
                  {{ $t("multidisciplinary_board.lbl_mems_type_chairman") }}:
                  <span>{{ form.preside }}</span>
                </p>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-between">
                  <p class="fs-14 txt-grey-900 mb-2" v-if="!currDisease.alias">
                    <span class="capitalize mr-1">{{ $t("multidisciplinary_board.lbl_age") }}:</span>
                    <span class="fw-700">
                      {{
                      currDisease &&
                      currDisease.patient_birthday &&
                      mdtUtils.getAges(new Date(currDisease.patient_birthday))
                      }}
                    </span>
                  </p>
                  <p class="fs-14 txt-grey-900 mb-2" v-else>
                    <span class="capitalize mr-1">{{ $t("multidisciplinary_board.lbl_age") }}:</span>
                    <span class="fw-700">
                      {{
                      currDisease &&
                      currDisease.alias &&
                      mdtUtils.getAges(currDisease.alias.alias_age)
                      }}
                    </span>
                  </p>
                  <p class="fs-14 txt-grey-900 mb-2">
                    {{ $t("multidisciplinary_board.lbl_genders") }}:
                    <span class="fw-700">
                      {{
                      currDisease &&
                      currDisease.patient_gender &&
                      getGender(currDisease.patient_gender)
                      }}
                    </span>
                  </p>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <p class="fs-14 txt-grey-900 mb-0 mr-2 w-50">
                    {{ $t("multidisciplinary_board.lbl_mdt_at") }}
                    <span class="text-danger">*</span>:
                  </p>
                  <input
                    id="inputDate"
                    type="datetime-local"
                    class="border fs-14 form-control bg-white"
                    v-model="form.mdt_time"
                    :readonly="!isOwner || hasSigned"
                  />
                </div>
                <div class="fs-14 txt-grey-900 mb-2 flex items-center gap-2">
                  <p class="m-0">{{ $t("multidisciplinary_board.lbl_mems_type_secretary") }}:</p>
                  <!-- <span>{{ form.secretary }}</span> -->
                  <input
                    type="text"
                    class="border fs-14 form-control bg-white flex-1"
                    v-model="form.secretary"
                    :readonly="!isOwner || hasSigned"
                  />
                </div>
              </div>
              <div class="col-12 d-flex mb-2">
                <div class="w-150px">
                  <p
                    class="fs-14 txt-grey-900 text-nowrap mb-0 mr-2"
                  >{{ $t("multidisciplinary_board.lbl_participant") }}:</p>
                </div>
                <div class="w--150px">
                  <textarea
                    :readonly="hasSigned"
                    class="form-control bg-white border"
                    v-model="form.members"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <div class="col-4 mb-2">
                <div
                  class="fs-14 txt-grey-700 mb-0 mr-2 text-nowrap"
                >{{ $t("multidisciplinary_board.lbl_profile_code") }}:</div>
                <div>
                  <input
                    type="text"
                    class="border fs-14 form-control bg-white"
                    v-model="form.profile_code"
                    :readonly="!isOwner || hasSigned"
                  />
                </div>
              </div>
              <div class="col-8">
                <div
                  class="fs-14 mb-0 mr-2 text-nowrap"
                >{{ $t("multidisciplinary_board.lbl_treat_duration") }}:</div>
                <div class="row">
                  <div class="col-6">
                    <div>
                      <input
                        type="datetime-local"
                        class="border fs-14 form-control bg-white"
                        v-model="form.treatment_time_start"
                        :readonly="!isOwner || hasSigned"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <input
                        type="datetime-local"
                        class="border fs-14 form-control bg-white"
                        v-model="form.treatment_time_end"
                        :readonly="!isOwner || hasSigned"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <div
                class="fs-14 fw-500 txt-grey-900 mb-2"
              >{{ $t("multidisciplinary_board.lbl_select_mdt_tem") }}</div>
              <el-select
                clearable
                filterable
                v-model="form.doc_template_id"
                class="w-full"
                :placeholder="$t('multidisciplinary_board.lbl_select_tem')"
                @change="handleChangeDocTemplate"
                remote
                :remote-method="getDocsTemplateList"
                :loading="loading"
              >
                <el-option
                  v-for="item in docsTemplateList"
                  :key="item.id + 'md'"
                  :label="handleGetTemLabel(item)"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="flex justify-between items-center border-t pt-3 mb-1">
            <p
              class="fs-16 fw-600 txt-grey-900 mb-0"
            >{{ $t("multidisciplinary_board.lbl_case_info") }}</p>
            <div class="flex justify-end items-center col-6">
              <svg
                class="mr-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7054 6.66623 19.4269 5.00529 17.7475 3.83187C16.0681 2.65845 14.0688 2.02917 12.02 2.02917C9.97126 2.02917 7.97195 2.65845 6.29252 3.83187C4.6131 5.00529 3.33461 6.66623 2.63001 8.59C2.63001 8.59 2.63001 8.59 2.63001 8.64C1.84314 10.8109 1.84314 13.1891 2.63001 15.36C2.63001 15.36 2.63001 15.36 2.63001 15.41C3.33461 17.3338 4.6131 18.9947 6.29252 20.1681C7.97195 21.3416 9.97126 21.9708 12.02 21.9708C14.0688 21.9708 16.0681 21.3416 17.7475 20.1681C19.4269 18.9947 20.7054 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1969 13.1891 22.1969 10.8109 21.41 8.64ZM4.26001 14C3.91324 12.6892 3.91324 11.3108 4.26001 10H6.12001C5.96004 11.3285 5.96004 12.6715 6.12001 14H4.26001ZM5.08001 16H6.48001C6.71473 16.8918 7.05022 17.7541 7.48001 18.57C6.49931 17.9019 5.67951 17.0241 5.08001 16ZM6.48001 8H5.08001C5.67088 6.97909 6.4802 6.10147 7.45001 5.43C7.03057 6.24725 6.70515 7.10942 6.48001 8ZM11 19.7C9.77178 18.7987 8.90916 17.4852 8.57001 16H11V19.7ZM11 14H8.14001C7.9534 12.6732 7.9534 11.3268 8.14001 10H11V14ZM11 8H8.57001C8.90916 6.51477 9.77178 5.20132 11 4.3V8ZM18.92 8H17.52C17.2853 7.10816 16.9498 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0909 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0909 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9798 17.7541 17.3153 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0868 11.3108 20.0868 12.6892 19.74 14Z"
                  fill="#41464B"
                />
              </svg>
              <span class="fs-16 fw-600 mr-3 text-black">{{ $t("common.select_lang") }}</span>
              <span class="fs-16 fw-600 mr-2">
                <span
                  v-for="(selectedLang, index) in listSelectedLang"
                  :key="index"
                  v-text="
                    index < listSelectedLang.length - 1
                      ? `${$t(`common.${selectedLang}`)}, `
                      : $t(`common.${selectedLang}`)
                  "
                />
              </span>
              <el-dropdown class="mr-3">
                <span :disabled="hasSigned" class="el-dropdown-link text-primary">
                  <span class="fs-16 fw-600">+{{ $t("multidisciplinary_board.btn_add") }}</span>
                  <i class="el-icon-arrow-down el-icon--right fs-16 fw-600"></i>
                </span>
                <el-dropdown-menu :slot="hasSigned ? '' : 'dropdown'">
                  <el-dropdown-item
                    v-for="(langCode, index) in Object.keys(
                      LANGUAGE_CODES_FOR_DISEASE
                    )"
                    :key="index"
                    :disabled="checkShowFieldMultiLang(langCode)"
                  >
                    <div
                      @click="setLang(langCode.toLowerCase())"
                    >{{ $t(`common.${langCode.toLowerCase()}`) }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <!-- <a
              href="javascript:;"
              class="mr-3"
              @click="($event) => addFieldsLang"
              >+ {{ $t("multidisciplinary_board.btn_add") }}</a
              >-->

              <el-tooltip
                :content="
                  $t(
                    'multidisciplinary_board.lbl_tooltip_multi_lang_write_case_info'
                  )
                "
                placement="top"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                    fill="black"
                  />
                </svg>
              </el-tooltip>
            </div>
          </div>

          <!-- <div>
            <div class="mb-3" v-if="isViLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>
                  {{
                  $t("multidisciplinary_board.lbl_treat_duration")
                  }}
                </span>
                <span
                  v-if="
                  $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.VI
                "
                  class="ml-1"
                >({{ $t(`common.vi`) }})</span>
              </label>
              <input
                type="text"
                v-model="form.treatment_time.vi"
                class="border form-control bg-white"
                :readonly="!isOwner"
              />
            </div>
            <div class="mb-3" v-if="isEnLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>
                  {{
                  $t("multidisciplinary_board.lbl_treat_duration")
                  }}
                </span>
                <span
                  v-if="
                  $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.EN
                "
                  class="ml-1"
                >({{ $t(`common.en`) }})</span>
              </label>
              <input
                type="text"
                v-model="form.treatment_time.en"
                class="border form-control bg-white"
                :readonly="!isOwner"
              />
            </div>
            <div class="mb-3" v-if="isFrLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>
                  {{
                  $t("multidisciplinary_board.lbl_treat_duration")
                  }}
                </span>
                <span
                  v-if="
                  $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.FR
                "
                  class="ml-1"
                >({{ $t(`common.fr`) }})</span>
              </label>
              <input
                type="text"
                v-model="form.treatment_time.fr"
                class="border form-control bg-white"
                :readonly="!isOwner"
              />
            </div>
          </div>-->

          <div>
            <div class="mb-3" v-if="isViLangShowField">
              <label class="fs-14 txt-grey-900 mb-2 required">
                <span>{{ $t("multidisciplinary_board.lbl_treatment_his") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.VI
                  "
                  class="ml-1"
                >({{ $t(`common.vi`) }})</span>
              </label>
              <EditorInput
                id="treatment_progress_vi-bb"
                :prop_text="form.treatment_progress.vi"
                @onChangeContent="
                  (content) => (form.treatment_progress.vi = content)
                "
                :disabled="!isOwner || hasSigned"
              />
            </div>
            <div class="mb-3" v-if="isEnLangShowField">
              <label class="fs-14 txt-grey-900 mb-2 required">
                <span>{{ $t("multidisciplinary_board.lbl_treatment_his") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.EN
                  "
                  class="ml-1"
                >({{ $t(`common.en`) }})</span>
              </label>
              <EditorInput
                id="treatment_progress_en-bb"
                :prop_text="form.treatment_progress.en"
                @onChangeContent="
                  (content) => (form.treatment_progress.en = content)
                "
                :disabled="!isOwner || hasSigned"
              />
            </div>
            <div class="mb-3" v-if="isFrLangShowField">
              <label class="fs-14 txt-grey-900 mb-2 required">
                <span>{{ $t("multidisciplinary_board.lbl_treatment_his") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.FR
                  "
                  class="ml-1"
                >({{ $t(`common.fr`) }})</span>
              </label>
              <EditorInput
                id="treatment_progress_fr-bb"
                :prop_text="form.treatment_progress.fr"
                @onChangeContent="
                  (content) => (form.treatment_progress.fr = content)
                "
                :disabled="!isOwner || hasSigned"
              />
            </div>
          </div>

          <div>
            <div class="mb-3" v-if="isViLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>{{ $t("multidisciplinary_board.lbl_conclusion") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.VI
                  "
                  class="ml-1"
                >({{ $t(`common.vi`) }})</span>
              </label>
              <EditorInput
                id="conclusion_vi-bb"
                :prop_text="form.conclusion.vi"
                @onChangeContent="(content) => (form.conclusion.vi = content)"
                :disabled="!isOwner || hasSigned"
              />
            </div>
            <div class="mb-3" v-if="isEnLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>{{ $t("multidisciplinary_board.lbl_conclusion") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.EN
                  "
                  class="ml-1"
                >({{ $t(`common.en`) }})</span>
              </label>
              <EditorInput
                id="conclusion_en-bb"
                :prop_text="form.conclusion.en"
                @onChangeContent="(content) => (form.conclusion.en = content)"
                :disabled="!isOwner || hasSigned"
              />
            </div>
            <div class="mb-3" v-if="isFrLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>{{ $t("multidisciplinary_board.lbl_conclusion") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.FR
                  "
                  class="ml-1"
                >({{ $t(`common.fr`) }})</span>
              </label>
              <EditorInput
                id="conclusion_fr-bb"
                :prop_text="form.conclusion.fr"
                @onChangeContent="(content) => (form.conclusion.fr = content)"
                :disabled="!isOwner || hasSigned"
              />
            </div>
          </div>

          <div>
            <div class="mb-3" v-if="isViLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>{{ $t("multidisciplinary_board.lbl_treatment_plan") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.VI
                  "
                  class="ml-1"
                >({{ $t(`common.vi`) }})</span>
              </label>
              <EditorInput
                id="treatment_vi-bb"
                :prop_text="form.treatment.vi"
                @onChangeContent="(content) => (form.treatment.vi = content)"
                :disabled="!isOwner || hasSigned"
              />
            </div>
            <div class="mb-3" v-if="isEnLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>{{ $t("multidisciplinary_board.lbl_treatment_plan") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.EN
                  "
                  class="ml-1"
                >({{ $t(`common.en`) }})</span>
              </label>
              <EditorInput
                id="treatment_en-bb"
                :prop_text="form.treatment.en"
                @onChangeContent="(content) => (form.treatment.en = content)"
                :disabled="!isOwner || hasSigned"
              />
            </div>
            <div class="mb-3" v-if="isFrLangShowField">
              <label class="fs-14 txt-grey-900 mb-2">
                <span>{{ $t("multidisciplinary_board.lbl_treatment_plan") }}</span>
                <span
                  v-if="
                    $lang.toUpperCase() !== LANGUAGE_CODES_NAME_FOR_DISEASE.FR
                  "
                  class="ml-1"
                >({{ $t(`common.fr`) }})</span>
              </label>
              <EditorInput
                id="treatment_fr-bb"
                :prop_text="form.treatment.fr"
                @onChangeContent="(content) => (form.treatment.fr = content)"
                :disabled="!isOwner || hasSigned"
              />
            </div>
          </div>

          <div
            class="mb-3 flex items-center"
            :class="
              currDisease.concluding && currDisease.concluding.sign
                ? 'justify-between'
                : 'justify-end'
            "
          >
            <div
              class="flex flex-wrap fs-14"
              v-if="currDisease.concluding && currDisease.concluding.sign"
            >
              <div>{{ currDisease.concluding.signer_name }} đã ký -&nbsp;</div>
              <div>{{ signAt }}</div>
            </div>
            <div class="flex" v-if="currDisease.status < 2">
              <button
                :disabled="!isOwner || loading"
                class="btn-action btn bg-pri bd-pri text-white"
                @click="submit()"
              >
                <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                <span v-else>{{ $t("health_book_page.lbl_save") }}</span>
              </button>
              <!-- <button
                :disabled="!isOwner || loading || !currDisease.concluding"
                class="btn-action btn bg-pri bd-pri text-white"
                @click="onUpdateStatus(2)"
              >
                <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                <span v-else>{{ $t("multidisciplinary_board.btn_approve") }}</span>
              </button>-->
            </div>

            <el-dropdown>
              <span class="el-dropdown-link">
                <button
                  :disabled="loading || !currDisease.concluding"
                  @click="onPrintDefault"
                  class="btn-action btn bg-pri bd-pri text-white"
                >
                  <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                  <span v-else>
                    {{
                    $t("multidisciplinary_board.lbl_print")
                    }}
                  </span>
                </button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(langCode, index) in Object.keys(
                    LANGUAGE_CODES_FOR_DISEASE
                  )"
                  :key="index"
                  :disabled="!checkShowFieldMultiLang(langCode)"
                >
                  <div @click="clickBB(langCode)">{{ $t(`common.${langCode.toLowerCase()}`) }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="flex">
              <button
                class="btn-action btn bg-pri bd-pri text-white"
                @click="showModalSaveDocTemplate(true)"
                :disabled="loading"
              >
                <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                <span v-else>
                  {{
                  $t("multidisciplinary_board.btn_save_tem")
                  }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TempBienBan
      :form="form"
      :save_pdf="save_pdf_count"
      :checkLang="checkLang"
      :print="print"
      @exportError="loading = false"
      @onSubmiting="onSubmiting"
      :mdt_disease="currDisease"
      :room_info="room_info"
      :multiLangData="multiLangData"
    />
    <ModalSaveDocumentTemplate
      ref="ModalSaveDocumentTemplateBB"
      :doc-content="docContent"
      :doc-type="MENU_EMR_TEMPLATE[1].value"
    />
  </div>
</template>

<script>
import _, { isNaN } from 'lodash'
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import TempBienBan from '../../templates/BienBan/TempBienBan.vue'
import {
  LANGUAGE_CODES_FOR_DISEASE,
  LANGUAGE_CODES_NAME_FOR_DISEASE,
  LANGUAGE_CODES_NUMBER_FOR_DISEASE
} from '../../lang'
import EditorInput from '@/components/EditorInput.vue'
import { mapState } from 'vuex'
import { SIGN_MDT_DISEASE } from '../../constants/appConstant'
import moment from 'moment'
import { MENU_EMR_TEMPLATE } from '../../utils/constants'
import ModalSaveDocumentTemplate from '../ElectronicMedicalRecords/ModalSaveDocumentTemplate.vue'

export default {
  name: 'BienBan',
  props: ['currDisease', 'room_info', 'checkin_members', 'mdtRoomMembers'],
  components: { TempBienBan, EditorInput, ModalSaveDocumentTemplate },
  data () {
    return {
      form: {
        address: '',
        treatment_time: {
          vi: '',
          en: '',
          fr: ''
        },
        treatment_progress: {
          vi: '',
          en: '',
          fr: ''
        },
        conclusion: {
          vi: '',
          en: '',
          fr: ''
        },
        treatment: {
          vi: '',
          en: '',
          fr: ''
        },
        treatment_time_start: '',
        treatment_time_end: '',
        mdt_time: appUtils.formatInputDateTime() || '',
        members: '',
        profile_code: '',
        preside: '',
        secretary: '',
        admission_room: '',
        admission_bed: '',
        doc_template_id: null
      },
      multilang: [
        {
          lang: LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()],
          treatment_progress: '',
          conclusion: '',
          treatment: '',
          treatment_time: ''
        }
      ],
      listSelectedLang: [],
      mdtUtils,
      doctor_id: null,
      save_pdf_count: 0,
      print: 0,
      loading: false,
      update_status: false,
      dtr_member: null,
      pre: null,
      checkLang: '',
      appUtils,
      showLang: '',
      isViLangShowField: false,
      isEnLangShowField: false,
      isFrLangShowField: false,
      LANGUAGE_CODES_FOR_DISEASE,
      LANGUAGE_CODES_NAME_FOR_DISEASE,
      LANGUAGE_CODES_NUMBER_FOR_DISEASE,
      validator: [],
      docsTemplateList: [],
      MENU_EMR_TEMPLATE
    }
  },
  created () {
    this.showLang =
      this.$lang || LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase()
    this.isViLangShowField =
      this.$lang === LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase()
    this.isEnLangShowField =
      this.$lang === LANGUAGE_CODES_NAME_FOR_DISEASE?.EN?.toLowerCase()
    this.isFrLangShowField =
      this.$lang === LANGUAGE_CODES_NAME_FOR_DISEASE?.FR?.toLowerCase()

    this.listSelectedLang = [this.showLang]
  },
  computed: {
    ...mapState({
      person: state => state.person
    }),
    langId () {
      return appUtils.getLangId(this.$lang || 'vi')
    },
    isMaster () {
      return (
        this.dtr_member?.role === 1 ||
        this.dtr_member?.role === 4 ||
        this.isOwner
      )
    },
    isPresident () {
      return this.doctor_id === this.room_info?.preside?.doctor_id
    },
    isOwner () {
      return true
    },
    textMul () {
      return this.$t(`common.${this.showLang}`)
    },
    addFieldsLang () {
      switch (this.showLang) {
        case LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase():
          if (!this.isViLangShowField) {
            this.isViLangShowField = true
            this.multilang.push({
              lang: LANGUAGE_CODES_FOR_DISEASE?.VI,
              treatment_progress: '',
              conclusion: '',
              treatment: '',
              treatment_time: ''
            })
          }
          break
        case LANGUAGE_CODES_NAME_FOR_DISEASE?.EN?.toLowerCase():
          if (!this.isEnLangShowField) {
            this.isEnLangShowField = true
            this.multilang.push({
              lang: LANGUAGE_CODES_FOR_DISEASE?.EN,
              treatment_progress: '',
              conclusion: '',
              treatment: '',
              treatment_time: ''
            })
          }
          break
        case LANGUAGE_CODES_NAME_FOR_DISEASE?.FR?.toLowerCase():
          if (!this.isFrLangShowField) {
            this.isFrLangShowField = true
            this.multilang.push({
              lang: LANGUAGE_CODES_FOR_DISEASE?.FR,
              treatment_progress: '',
              conclusion: '',
              treatment: '',
              treatment_time: ''
            })
          }
          break
        default:
          break
      }
    },
    patientDiagnostic () {
      if (this.currDisease) {
        if (this.currDisease.multilang?.length) {
          const langId = this.langId
          const r = this.currDisease.multilang.find(
            item => item.lang === langId
          )
          if (!r) return this.currDisease.patient_diagnostic
          else return r.patient_diagnostic
        }
        return this.currDisease.patient_diagnostic
      }
      return ''
    },
    multiLangData () {
      return this.multilang
    },
    signAt () {
      return moment(this.currDisease?.concluding?.sign?.sign_at).format(
        'HH:mm - DD/MM/YYYY'
      )
    },
    hasSigned () {
      return (
        Boolean(this.currDisease) &&
        Boolean(
          this.currDisease.concluding?.sign ||
            this.currDisease.operation?.sign ||
            this.currDisease.status === 2
        )
      )
    },
    docContent () {
      return {
        patient_diseases_current_cure: {
          label:
            'Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc người bệnh',
          value: this.form.treatment_progress.vi,
          editor: 1
        },
        conclusion: {
          label: 'Kết luận (sau khi đã khám lại và thảo luận)',
          value: this.form.conclusion.vi,
          editor: 1
        },
        treatment: {
          label: 'Hướng điều trị tiếp',
          value: this.form.treatment.vi,
          editor: 1
        }
      }
    }
  },
  watch: {
    currDisease (d) {
      this.handlerDisease(d)
    },
    checkin_members (members) {
      this.handlerMemberstring()
    },
    room_info (r) {
      this.handlerRoomInfo()
    },
    person (data) {
      if (data) {
        this.form.admission_room = this.person.admission_room
        this.form.admission_bed = this.person.admission_bed
      } else {
        this.form.admission_room = ''
        this.form.admission_bed = ''
      }
    }
  },
  mounted () {
    this.doctor_id = this.$user?.doctor.id
  },
  methods: {
    getLang (l) {
      return l === 1
        ? 'Vietnamese'
        : l === 2
          ? 'English'
          : l === 3
            ? 'Français'
            : ''
    },

    checkShowFieldMultiLang (langCode) {
      switch (langCode) {
        case LANGUAGE_CODES_NAME_FOR_DISEASE?.VI:
          return this.isViLangShowField
        case LANGUAGE_CODES_NAME_FOR_DISEASE?.EN:
          return this.isEnLangShowField
        case LANGUAGE_CODES_NAME_FOR_DISEASE?.FR:
          return this.isFrLangShowField

        default:
          break
      }
    },
    setLang (langVal) {
      this.showLang = langVal

      const hasSelectedLang = this.listSelectedLang.includes(langVal)

      if (hasSelectedLang) return

      this.listSelectedLang.push(langVal)

      return this.addFieldsLang
    },
    printWithLang (l) {
      this.checkLang = l
      const data = _.find(this.multilang, { lang: l })
      this.checkLang = data.lang
      if (data) {
        this.multilang.push({ multilang: data })
      }
      this.loading = true
      this.checkLang = l
      setTimeout(() => {
        this.loading = false
      }, 3000)
      setTimeout(() => {
        this.print++
      }, 100)
    },
    handlerRoomInfo () {
      if (!this.room_info) return
      this.dtr_member = this.room_info?.members?.find(
        m => m.doctor_id === this.doctor_id
      )
      this.pre = this.room_info?.preside
      this.form.preside = this.pre?.doctor?.User?.name
    },
    handlerDisease (d) {
      if (d) {
        let cloneMultiLangDataToConcluding = []

        if (d?.medical_record) {
          this.form.profile_code = d?.medical_record?.ref_id
        }

        if (d.concluding) {
          this.form.address = d.concluding.address

          if (d.concluding.members) this.form.members = d.concluding.members
          this.form.profile_code = d.concluding.profile_code || ''
          this.form.preside =
            d.concluding.preside || d?.preside || this.pre?.doctor?.name || ''
          this.form.secretary = d.concluding.secretary || d.doctor?.name || ''

          this.form.mdt_time = d.concluding.mdt_time
            ? appUtils.formatInputDateTime(d.concluding.mdt_time)
            : appUtils.formatInputDateTime(this.room_info?.start_time)

          this.form.treatment_time_start = d.concluding.treatment_time_start
            ? appUtils.formatInputDateTime(d.concluding.treatment_time_start)
            : ''

          this.form.treatment_time_end = d.concluding.treatment_time_end
            ? appUtils.formatInputDateTime(d.concluding.treatment_time_end)
            : ''

          if (
            d?.multilang?.length > 0 &&
            d?.concluding?.multilang?.length === 0
          ) {
            cloneMultiLangDataToConcluding = d.multilang
          } else {
            cloneMultiLangDataToConcluding = d.concluding.multilang
          }

          this.showLang =
            cloneMultiLangDataToConcluding.length > 0 &&
            typeof LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
              cloneMultiLangDataToConcluding?.[0]?.['lang']
            ]?.toLowerCase() !== 'undefined'
              ? LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                  cloneMultiLangDataToConcluding?.[0]?.['lang']
                ]?.toLowerCase()
              : this.$lang || LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase()

          this.listSelectedLang = []

          // Reset fields multi lang
          this.isViLangShowField = false
          this.isEnLangShowField = false
          this.isFrLangShowField = false

          const newMultiLang = []

          if (cloneMultiLangDataToConcluding.length > 0) {
            _.forEach(cloneMultiLangDataToConcluding, value => {
              let _obj = {
                lang: Number(value.lang),
                treatment_progress:
                  value.patient_diseases_current_cure ||
                  value.treatment_progress ||
                  '',
                conclusion: value.conclusion || '',
                treatment: value.treatment || '',
                treatment_time: value.treatment_time
              }

              if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE?.VI) {
                this.isViLangShowField = true
                this.form.treatment_progress.vi =
                  value.patient_diseases_current_cure ||
                  value.treatment_progress ||
                  ''
                this.form.conclusion.vi = value.conclusion || ''
                this.form.treatment.vi = value.treatment || ''
                this.form.treatment_time.vi = value.treatment_time || ''
                this.listSelectedLang.push(
                  LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                    Number(value.lang)
                  ].toLowerCase()
                )
              }

              if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE?.EN) {
                this.isEnLangShowField = true
                this.form.treatment_progress.en =
                  value.patient_diseases_current_cure ||
                  value.treatment_progress ||
                  ''
                this.form.conclusion.en = value.conclusion || ''
                this.form.treatment.en = value.treatment || ''
                this.form.treatment_time.en = value.treatment_time || ''
                this.listSelectedLang.push(
                  LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                    Number(value.lang)
                  ].toLowerCase()
                )
              }

              if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE?.FR) {
                this.isFrLangShowField = true
                this.form.treatment_progress.fr =
                  value.patient_diseases_current_cure ||
                  value.treatment_progress ||
                  ''
                this.form.conclusion.fr = value.conclusion || ''
                this.form.treatment.fr = value.treatment || ''
                this.form.treatment_time.fr = value.treatment_time || ''
                this.listSelectedLang.push(
                  LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                    Number(value.lang)
                  ].toLowerCase()
                )
              }

              newMultiLang.push(_obj)
            })
          } else {
            this.listSelectedLang = [this.showLang]
            this.isViLangShowField =
              this.showLang ===
              LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase()
            this.isEnLangShowField =
              this.showLang ===
              LANGUAGE_CODES_NAME_FOR_DISEASE?.EN?.toLowerCase()
            this.isFrLangShowField =
              this.showLang ===
              LANGUAGE_CODES_NAME_FOR_DISEASE?.FR?.toLowerCase()
          }

          this.multilang = newMultiLang
        } else {
          this.form.address = d.patient_address
          this.form.profile_code =
            d?.medical_record?.ref_id || d?.operation?.code || ''
          this.form.preside = d?.preside || this.pre?.doctor?.name || ''
          this.form.secretary = d.doctor?.name || ''
          this.form.mdt_time = d.mdt_date
            ? appUtils.formatInputDateTime(d.mdt_date)
            : appUtils.formatInputDateTime()
          this.form.treatment_time_start = d.admission_date
            ? appUtils.formatInputDateTime(d.admission_date)
            : ''
          this.form.treatment_time_end = d.mdt_date
            ? appUtils.formatInputDateTime(d.mdt_date)
            : ''
          if (d.multilang.length > 0) {
            cloneMultiLangDataToConcluding = d.multilang
          } else {
            const obj = {
              lang: 1,
              patient_diseases_current_cure:
                d?.operation?.medical_history ||
                d?.patient_diseases_current_cure ||
                null,
              conclusion: d?.operation?.conclusion || d?.conclusion || null,
              treatment: d?.treatment || null,
              treatment_time: d?.treatment_time || null
            }

            cloneMultiLangDataToConcluding.push(obj)
          }

          this.showLang =
            cloneMultiLangDataToConcluding.length > 0 &&
            typeof LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
              cloneMultiLangDataToConcluding?.[0]?.['lang']
            ]?.toLowerCase() !== 'undefined'
              ? LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                  cloneMultiLangDataToConcluding?.[0]?.['lang']
                ]?.toLowerCase()
              : this.$lang || LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase()

          this.listSelectedLang = []

          // Reset fields multi lang
          this.isViLangShowField = false
          this.isEnLangShowField = false
          this.isFrLangShowField = false

          const newMultiLang = []

          if (cloneMultiLangDataToConcluding.length > 0) {
            _.forEach(cloneMultiLangDataToConcluding, value => {
              let _obj = {
                lang: Number(value.lang),
                treatment_progress: value.patient_diseases_current_cure,
                conclusion: value.conclusion,
                treatment: value.treatment,
                treatment_time: value.treatment_time
              }

              if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE?.VI) {
                this.isViLangShowField = true
                this.form.treatment_progress.vi =
                  value.patient_diseases_current_cure || ''
                this.form.conclusion.vi = value.conclusion || ''
                this.form.treatment.vi = value.treatment || ''
                this.form.treatment_time.vi = value.treatment_time || ''
                this.listSelectedLang.push(
                  LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                    Number(value.lang)
                  ].toLowerCase()
                )
              }

              if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE?.EN) {
                this.isEnLangShowField = true
                this.form.treatment_progress.en =
                  value.patient_diseases_current_cure || ''
                this.form.conclusion.en = value.conclusion || ''
                this.form.treatment.en = value.treatment || ''
                this.form.treatment_time.en = value.treatment_time || ''
                this.listSelectedLang.push(
                  LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                    Number(value.lang)
                  ].toLowerCase()
                )
              }

              if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE?.FR) {
                this.isFrLangShowField = true
                this.form.treatment_progress.fr =
                  value.patient_diseases_current_cure || ''
                this.form.conclusion.fr = value.conclusion || ''
                this.form.treatment.fr = value.treatment || ''
                this.form.treatment_time.fr = value.treatment_time || ''
                this.listSelectedLang.push(
                  LANGUAGE_CODES_NUMBER_FOR_DISEASE?.[
                    Number(value.lang)
                  ].toLowerCase()
                )
              }

              newMultiLang.push(_obj)
            })
          } else {
            this.listSelectedLang = [this.showLang]
            this.isViLangShowField =
              this.showLang ===
              LANGUAGE_CODES_NAME_FOR_DISEASE?.VI?.toLowerCase()
            this.isEnLangShowField =
              this.showLang ===
              LANGUAGE_CODES_NAME_FOR_DISEASE?.EN?.toLowerCase()
            this.isFrLangShowField =
              this.showLang ===
              LANGUAGE_CODES_NAME_FOR_DISEASE?.FR?.toLowerCase()
          }

          this.multilang = newMultiLang
        }
      } else {
        this.form = {
          address: '',
          mdt_time: appUtils.formatInputDateTime(),
          treatment_time_start: '',
          treatment_time_end: '',
          members: '',
          profile_code: '',
          preside: '',
          secretary: ''
        }
      }
    },
    handlerMemberstring () {
      // let members = this.checkin_members
      // if (members && members.count && !this.form.members) {
      //   let string = ''
      //   members.data.forEach(m => {
      //     if (m.status === 1) {
      //       string += m?.doctor?.name + ', '
      //     }
      //   })
      //   this.form.members = string
      // }
    },
    submit (update) {
      let isError = false
      this.checkLang = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]

      const newMultiLang = this.multilang?.map(value => {
        let data = {
          mdt_room_disease_concluding_id: null
        }

        if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.VI) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.vi,
            treatment_progress: this.form.treatment_progress.vi,
            conclusion: this.form.conclusion.vi,
            treatment: this.form.treatment.vi
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.EN) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.en,
            treatment_progress: this.form.treatment_progress.en,
            conclusion: this.form.conclusion.en,
            treatment: this.form.treatment.en
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.FR) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.fr,
            treatment_progress: this.form.treatment_progress.fr,
            conclusion: this.form.conclusion.fr,
            treatment: this.form.treatment.fr
          }
        }

        return data
      })

      if (!newMultiLang?.length) {
        isError = true
        alert(this.$t('multidisciplinary_board.invalid_save_concluding'))
        return
      } else {
        _.forEach(newMultiLang, value => {
          if (
            !value.treatment_progress ||
            value.treatment_progress?.trim() === '' ||
            value.treatment_progress === null ||
            !value.treatment_progress?.length
          ) {
            isError = true
          }
        })
      }

      if (isError) {
        alert(this.$t('multidisciplinary_board.invalid_save_concluding'))
        return
      }
      if (this.form.mdt_time == 'Invalid date' || !this.form.mdt_time) {
        alert(this.$t('multidisciplinary_board.invalid_save_concluding'))
        return
      }

      this.loading = true
      setTimeout(() => {
        this.update_status = update
        this.save_pdf_count++
      }, 100)
    },

    formatHmDMY (date) {
      let string = window.moment(date).format('HH:mm DD/MM/YYYY')
      string.split(' ').join(' ngày ')
      return string
    },

    onSubmiting (base64) {
      if (!this.currDisease) return
      if (this.currDisease.concluding) {
        this.mdtConcludingUpdate(base64)
      } else {
        this.mdtConcludingCreate(base64)
      }
    },

    setFormData () {
      let self = this
      let formData = new FormData()

      formData.append(`name`, self.currDisease.patient_name)
      formData.append(
        `age`,
        self.currDisease?.alias &&
          self.currDisease?.alias.status &&
          self.currDisease?.alias.alias_age
          ? self.getAges(self.currDisease?.alias.alias_age)
          : self.currDisease?.patient_birthday
            ? self.getAges(new Date(self.currDisease?.patient_birthday))
            : null
      )
      formData.append(`sex`, self.currDisease.patient_gender)
      formData.append(`address`, self.currDisease.patient_address)
      formData.append(`mdt_room_disease_id`, self.currDisease.id)
      formData.append(
        `profile_code`,
        self.form.profile_code ||
          self.currDisease?.medical_record?.ref_id ||
          self.currDisease?.concluding?.profile_code ||
          self.currDisease?.code
      )
      formData.append(`preside`, self.currDisease.preside)
      formData.append(`secretary`, self.currDisease.secretary)
      formData.append(
        `mdt_time`,
        self.form.mdt_time
          ? appUtils.formatInputDateTime(self.form.mdt_time)
          : appUtils.formatInputDateTime()
      )
      formData.append(
        `treatment_time_start`,
        self.form.treatment_time_start
          ? appUtils.formatInputDateTime(self.form.treatment_time_start)
          : ''
      )
      formData.append(
        `treatment_time_end`,
        self.form.treatment_time_end
          ? appUtils.formatInputDateTime(self.form.treatment_time_end)
          : ''
      )

      const newMultiLang = this.multilang?.map((value, index) => {
        let data = {
          mdt_room_disease_concluding_id:
            this.currDisease.concluding?.id || null
        }

        if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.VI) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.vi,
            treatment_progress: this.form.treatment_progress.vi,
            conclusion: this.form.conclusion.vi,
            treatment: this.form.treatment.vi
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.EN) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.en,
            treatment_progress: this.form.treatment_progress.en,
            conclusion: this.form.conclusion.en,
            treatment: this.form.treatment.en
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.FR) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.fr,
            treatment_progress: this.form.treatment_progress.fr,
            conclusion: this.form.conclusion.fr,
            treatment: this.form.treatment.fr
          }
        }

        return data
      })

      _.forEach(newMultiLang, (value, index) => {
        typeof value.lang !== 'undefined' &&
          formData.append(`multilang[${index}][lang]`, value.lang)
        typeof value.mdt_room_disease_concluding_id !== 'undefined' &&
          formData.append(
            `multilang[${index}][mdt_room_disease_concluding_id]`,
            value.mdt_room_disease_concluding_id
          )
        value.treatment_time &&
          formData.append(
            `multilang[${index}][treatment_time]`,
            value.treatment_time
          )
        value.treatment_progress &&
          formData.append(
            `multilang[${index}][treatment_progress]`,
            value.treatment_progress
          )
        value.conclusion &&
          formData.append(`multilang[${index}][conclusion]`, value.conclusion)
        value.treatment &&
          formData.append(`multilang[${index}][treatment]`, value.treatment)
      })

      for (const key in this.form) {
        if (!_.isNil(this.form[key])) formData.set(key, this.form[key])
      }

      formData.delete('treatment_progress')
      formData.delete('conclusion')
      formData.delete('treatment')
      formData.delete('treatment_time')

      return [formData, newMultiLang]
    },

    async savePDF ({ multilang, base64 }) {
      const self = this
      if (!multilang.length || !base64.length) return

      try {
        const formData = new FormData()

        _.forEach(multilang, (value, index) => {
          typeof value.mdt_room_disease_concluding_id !== 'undefined' &&
            formData.append(
              `multilang[${index}][object_id]`,
              value?.mdt_room_disease_concluding_id
            )
          formData.append(`multilang[${index}][object_type]`, 1)
          formData.append(`multilang[${index}][lang]`, value.lang)
          formData.append(`multilang[${index}][content]`, base64[index])
        })

        await self.$rf.getRequest('DoctorRequest').setEmrDocument(formData)
      } catch (error) {
        console.log(error)
      }
    },

    async mdtConcludingCreate (base64 = null) {
      let self = this
      const [formData, newMultiLang] = self.setFormData()

      const res = await self.$rf
        .getRequest('DoctorRequest')
        .mdtConcludingCreate(formData)
        .finally(() => {})

      if (res.data) {
        let tempNewMultiLang = []
        _.forEach(newMultiLang, value => {
          tempNewMultiLang.push({
            ...value,
            mdt_room_disease_concluding_id: res.data.id
          })
        })
        await self
          .savePDF({
            multilang: tempNewMultiLang,
            base64
          })
          .finally(() => {
            self.$emit('refresh')
            this.form.doc_template_id = null
            self.$toast.open({
              message: self.$t('multidisciplinary_board.lbl_saved_msg'),
              type: 'success'
            })
            self.loading = false
            // self.$emit('refresh')
          })
      }
    },

    async mdtConcludingUpdate (base64 = null) {
      let self = this
      const [formData, newMultiLang] = self.setFormData()

      const res = await self.$rf
        .getRequest('DoctorRequest')
        .mdtConcludingUpdate(self.currDisease.concluding.id, formData)
        .finally(() => {
          // self.finishCreateOrUpdate()
          // self.loading = false
          // self.$emit('refresh')
        })

      if (res.data) {
        await self
          .savePDF({
            multilang: newMultiLang,
            base64
          })
          .finally(() => {
            this.form.doc_template_id = null
            self.loading = false

            self.$toast.open({
              message: self.$t('multidisciplinary_board.lbl_saved_msg'),
              type: 'success'
            })
            self.$emit('refresh')
          })
      }
    },

    async updateMdtDiseases (id) {
      let self = this
      let formData = new FormData()

      formData.append(`mdt_room_id`, self.currDisease.mdt_room_id)
      formData.append(`type`, self.currDisease.type ? 1 : 0)
      formData.append(`date`, self.currDisease.mdt_date)

      const newMultiLang = this.multilang?.map((value, index) => {
        let data = {}

        if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.VI) {
          data = {
            ...value,
            ...data,
            treatment_progress: this.form.treatment_progress.vi,
            conclusion: this.form.conclusion.vi,
            treatment: this.form.treatment.vi
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.EN) {
          data = {
            ...value,
            ...data,
            treatment_progress: this.form.treatment_progress.en,
            conclusion: this.form.conclusion.en,
            treatment: this.form.treatment.en
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.FR) {
          data = {
            ...value,
            ...data,
            treatment_progress: this.form.treatment_progress.fr,
            conclusion: this.form.conclusion.fr,
            treatment: this.form.treatment.fr
          }
        } else {
          data = { ...value, ...data }
        }

        return data
      })

      _.forEach(newMultiLang, (value, index) => {
        typeof value.lang !== 'undefined' &&
          formData.append(`multilang[${index}][lang]`, value.lang)
        value.treatment_progress &&
          formData.append(
            `multilang[${index}][treatment_progress]`,
            value.treatment_progress
          )
        value.conclusion &&
          formData.append(`multilang[${index}][conclusion]`, value.conclusion)
        value.treatment &&
          formData.append(`multilang[${index}][treatment]`, value.treatment)
      })

      // await self.$rf
      //   .getRequest('DoctorRequest')
      //   .updateMdtDiseases(id, formData)
      //   .finally(() => {
      //     return true
      //   })
    },

    async onUpdateStatus (stt) {
      let isError = false

      const newMultiLang = this.multilang?.map(value => {
        let data = {
          mdt_room_disease_concluding_id: null
        }

        if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.VI) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.vi,
            treatment_progress: this.form.treatment_progress.vi,
            conclusion: this.form.conclusion.vi,
            treatment: this.form.treatment.vi
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.EN) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.en,
            treatment_progress: this.form.treatment_progress.en,
            conclusion: this.form.conclusion.en,
            treatment: this.form.treatment.en
          }
        } else if (Number(value.lang) === LANGUAGE_CODES_FOR_DISEASE.FR) {
          data = {
            ...value,
            ...data,
            treatment_time: this.form.treatment_time.fr,
            treatment_progress: this.form.treatment_progress.fr,
            conclusion: this.form.conclusion.fr,
            treatment: this.form.treatment.fr
          }
        }

        return data
      })

      if (!newMultiLang?.length) {
        isError = true
        alert(this.$t('multidisciplinary_board.invalid_save_concluding'))
        return
      } else {
        _.forEach(newMultiLang, value => {
          if (
            !value.treatment_progress ||
            value.treatment_progress?.trim() === '' ||
            value.treatment_progress === null ||
            !value.treatment_progress?.length
          ) {
            isError = true
          }
        })
      }

      if (isError) {
        alert(this.$t('multidisciplinary_board.invalid_save_concluding'))
        return
      }

      const r = confirm(
        this.$t('multidisciplinary_board.lbl_approve_confirm_msg')
      )

      if (!r) return

      await this.mdtDiseaseStatus(stt).then(res => {
        this.$emit('refresh')
      })
    },

    async mdtDiseaseStatus (stt) {
      const self = this

      if (!self.currDisease || isNaN(stt)) return

      try {
        const params = {
          status: stt
        }

        this.loading = true
        await self.$rf
          .getRequest('DoctorRequest')
          .mdtDiseaseStatus(self.currDisease.id, params)
          .then(() => {
            this.$message({
              message: this.$t('multidisciplinary_board.lbl_saved_msg'),
              type: 'success'
            })
            this.loading = false
            return true
          })
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },

    async finishCreateOrUpdate () {
      try {
        if (this.update_status && this.currDisease.status !== 2) {
          await this.mdtDiseaseStatus(this.currDisease.id, 2)
        }
        await this.updateMdtDiseases(this.currDisease.id)
      } catch (error) {
      } finally {
        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_saved_msg'),
          type: 'success'
        })
        this.loading = false
        this.$emit('refresh')
      }
    },
    getAges (value) {
      if (!value) return

      if (value instanceof Date) {
        let bd = window.moment(value, 'YYYY-MM-DD')
        let age = window.moment().diff(bd, 'years')
        return age
      } else {
        return value
      }
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    showBienBan (show) {
      window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
    },
    onPrint (l) {
      const data = _.find(this.multilang, { lang: l })
      this.checkLang = data.lang
      if (data) {
        this.multilang.push({ multilang: data })
      }
      this.loading = true
      this.checkLang = l
      setTimeout(() => {
        this.loading = false
      }, 3000)
      setTimeout(() => {
        this.print++
      }, 100)
    },
    onPrintDefault () {
      if (!this.multilang.length) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 3000)
        this.print++
      }
    },
    clickBB (langCode) {
      const self = this
      const langId = appUtils.getLangId(langCode.toLowerCase())

      if (!self.currDisease?.concluding?.multilang?.length) {
        alert(this.$t('multidisciplinary_board.lbl_notfound'))
        return
      }

      const multiLangData = self.currDisease?.concluding?.multilang
      const data = _.find(multiLangData, { lang: langId })

      if (data?.file_name_printing) {
        this.openUrl({ urlFile: data.file_name_printing })
      }
    },
    async openUrl ({ urlFile, print = false }) {
      if (!urlFile) {
        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_notfound'),
          type: 'error'
        })
        return
      }
      window.open(urlFile)

      // try {
      //   this.loading = true

      //   const res = await this.$rf.getRequest('DoctorRequest').getEmrDocument({
      //     fileName
      //   })
      //   if (res.data) {
      //     if (print) {
      //       const printWindow = window.open(res.data?.documentLink)
      //       printWindow.print()

      //       printWindow.onafterprint = function () {
      //         printWindow.close()
      //       }
      //     } else {
      //       window.open(res.data?.documentLink)
      //     }
      //   }
      //   this.loading = false
      // } catch (error) {
      //   console.log(error)
      //   this.loading = false
      // }
    },
    async handleSignBBHC () {
      const params = {
        id: this.currDisease?.concluding?.id,
        type: SIGN_MDT_DISEASE.BBHC,
        method: 1
      }

      const r = confirm(
        this.$t('multidisciplinary_board.lbl_approve_confirm_msg')
      )

      if (!r) return

      try {
        await this.$rf.getRequest('DoctorRequest').signMdtDiseaseDetail(params)

        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_signed_msg'),
          type: 'success'
        })
        this.$emit('refresh')
      } catch (error) {
        console.log({ error })
      }
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[1].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then(r => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              item =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find(item => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}

      this.form = {
        ...this.form,
        conclusion: { vi: contentObj.conclusion?.value },
        treatment_progress: {
          vi: contentObj.patient_diseases_current_cure?.value
        },
        treatment: { vi: contentObj.treatment?.value }
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    showModalSaveDocTemplate (show) {
      this.$refs.ModalSaveDocumentTemplateBB.showModalSaveDocTemplate(show)
      // window.$('#modalSaveDocTemplate-2').modal(show ? 'show' : 'hide')
    },
    openModal (show) {
      if (show) {
        this.handlerMemberstring()
        this.handlerRoomInfo()
        this.getDocsTemplateList()
      }
      window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
.form-control {
  height: unset;
}

.btn-action {
  width: fit-content;
  margin-left: 24px;
}

.fs-18 {
  font-size: 18px;
}

.w-150px {
  width: 150px;
}

.w--150px {
  width: calc(100% - 150px);
}

.form-control {
  border-radius: 0 !important;
}
</style>
